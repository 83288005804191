<template>
  <div class="bio_link_main_view">
    <div class="view_inner d-flex flex-column">
      <div class="view_top d-flex align-items-center">
        <h4 class="title">Select Theme and Finalize</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{ name: 'bio_links' }">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <router-link
            :to="{ name: 'select_content' }"
            class="btn---cta light-blue btn-round"
          >
            <span>Go Back</span>
          </router-link>
          <div class="circles">
            <span class="dotted_circle active"></span>
            <span class="dotted_circle active"></span>
          </div>
          <button
            @click.prevent="validateAndStoreWithBio()"
            class="d-inline-flex btn---cta btn-blue btn-round"
          >
            <span>Save Link</span>
            <clip-loader v-if="bioLinkSaveLoader" class="ml-2" :size="'14px'" :color="'#ffff'"></clip-loader>
          </button>
        </div>
        <div class="input-block-list">
          <div class="border-block-section">
            <div class="block-area">
              <div class="row block-area">
                <div class="col-md-9 block_content">
                  <!--                  select              brands-->
                  <div
                    class="
                      mt-3
                      mb-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                  >
                    <div class="content">
                      <div class="url">Select Brand</div>
                      <div class="name">
                        <div class="validation_input">
                          <b-dropdown right class="mt-2 dropdown-menu-right default_style_dropdown" :no-caret="true"
                                      ref="bio_brand_dropdown_menu">
                            <div
                              :class="{'input-field-error': validations.brand_required}"
                              class="dropdown_header with-shadow d-flex align-items-center rounded_shape"
                              slot="button-content"
                              data-cy="select-brand"
                              @click="fetchBrandOnDropdown"
                            >
                              <span class="text">
                                <template>{{ getSelectedBioBrand() }}</template>
                                </span>

                              <span class="arrow_icon ml-auto">
                                <i class="fal fa-angle-down ml-3"></i>
                              </span>
                            </div>
                            <ul id="brand-dropdown-scroll"
                                @scroll="lazyScrollBrand('brand-dropdown-scroll','fetchBrandList',1)" class="inner">
                              <template
                                v-if="getBrandList && getBrandList.length && getBrandList.length > 7 || getBrand.search">
                                <div
                                  class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
                                  <input placeholder="Search for brand"
                                         type="text"
                                         class="search_input_block_for_dropdown" style="padding: 8px"
                                         @input="debounceBrandDropdownSearch"
                                         :value="getBrand.search">
                                </div>
                                <hr>
                              </template>
                              <template v-if="getBrandList && getBrandList.length">
                                <li v-for="brand in getComponentBrandList"
                                    @click.prevent="
                                    selectBrandForBio(brand._id, brand.avatar)
                                  "
                                    class="list_item_li"
                                    data-cy="s-brand"
                                >
                                  <div
                                    class="
                                      d-flex
                                      align-items-center
                                      profile__box
                                    "
                                  >
                                    <div class="avatar">
                                      <div
                                        class="brand_img"
                                        :style="brandImageLink(brand.avatar)"
                                      ></div>
                                    </div>
                                    <div class="content">
                                      <div class="name">
                                        {{ limitTextLength(brand.name, 34) }}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li v-if="getBrandLoaders.retrieve" class="list_item_li">
                                  <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
                                </li>
                              </template>
                              <li v-else-if="getBrandLoaders.retrieve" class="list_item_li">
                                <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
                              </li>
                              <li
                                v-if="!getBrandLoaders.retrieve && !getComponentBrandList.length && getBrand.search.length"
                                class="no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}
                              </li>
                              <li class="no_text_li" v-else-if="!getBrandLoaders.retrieve && !getBrandList.length">
                                You do not have any brand.
                              </li>
                            </ul>
                          </b-dropdown>
                        </div>
                        <span class="input-error" v-if="validations.brand_required">Please select a brand.</span>
                      </div>
                    </div>
                  </div>
                  <!-- Page title -->
                  <div
                    :class="{'input-field-error': validations.title_required || validations.title_length}"
                    class="
                      mt-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                  >
                    <div class="content">
                      <div class="url">Page Title</div>
                      <div class="name">
                        <div class="validation_input">
                          <input
                            class=""
                            type="text"
                            placeholder="Add a title..."
                            v-model="getBioLinkAdd.page_title"
                            data-cy="page-title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-error" v-if="validations.title_required">Please enter the page title.</span>
                  <span class="input-error"
                        v-if="validations.title_length">The title should not be greater than 100 characters.</span>
                  <!-- Page sub title -->
                  <div
                    :class="{'input-field-error': validations.page_sub_title_length}"
                    class="
                      mt-3
                      mb-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                  >
                    <div class="content">
                      <div class="url">Page Sub-Title</div>
                      <div class="name">
                        <div class="validation_input">
                          <input
                            class=""
                            type="text"
                            placeholder="Add a sub-title..."
                            v-model="getBioLinkAdd.page_sub_title"
                            data-cy="page-sub-title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-error" v-if="validations.page_sub_title_length">The Page Sub-Title should not be greater than 100 characters.</span>
                  <!-- select domain -->
                  <div
                    class="
                      mt-3
                      mb-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                    data-cy="select-domain"
                  >
                    <div class="content">
                      <div class="url">Select Domain</div>
                      <div class="name">
                        <div class="validation_input">
                          <b-dropdown
                            right
                            class="
                              mt-2
                              dropdown-menu-right
                              default_style_dropdown
                            "
                            :no-caret="true"
                            ref="bio_domains_dropdown_menu"
                          >
                            <div
                              class="
                                dropdown_header
                                with-shadow
                                d-flex
                                align-items-center
                                rounded_shape
                              "
                              slot="button-content"
                            >
                              <template>
                                <span class="text">{{
                                    trimUrl(getBioLinkAdd.domain.url)
                                  }}</span>
                              </template>

                              <span class="arrow_icon ml-auto">
                                <i class="fal fa-angle-down ml-3"></i>
                              </span>
                            </div>

                            <ul class="inner">
                              <div class="dropdown_section_domains">
                                {{ getSiteDetails.agency_name }} Domains
                              </div>

                              <li
                                @click.prevent="
                                  selectBioDomain({
                                    shorten_domain: getSiteDetails.isWhiteLabel ? trimUrl(getSiteDetails.custom_domain_name) : default_domain ? default_domain.registered_domain : '',
                                    domain_id: getSiteDetails.isWhiteLabel ? getSiteDetails.custom_domain_id : '',
                                    url: getSiteDetails.isWhiteLabel ? getBioDefaultCustomDomain.url : default_domain.url
                                  })
                                "
                                class="list_item_li"
                                data-cy="select-domain"
                              >
                                <div
                                  class="d-flex align-items-center profile__box"
                                >
                                  <div class="content">
                                    <div class="name">{{
                                        getSiteDetails.isWhiteLabel ? trimUrl(getSiteDetails.custom_domain_name) : default_domain ? default_domain.registered_domain : ''
                                      }}
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <div class="dropdown_section_domains">
                                Custom Domains
                              </div>

                              <template
                                v-if="getDomains.items.length > 0"
                                v-for="domain in getDomains.items"
                              >
                                <li
                                  v-if="domain.connectivity && domain._id !== getSiteDetails.custom_domain_id"
                                  @click.prevent="
                                    selectBioDomain({
                                      shorten_domain: trimUrl(domain.url),
                                      domain_id: domain._id,
                                      url:domain.url
                                    })
                                  "
                                  class="list_item_li"
                                  data-cy="s-domain"
                                >
                                  <div
                                    class="
                                      d-flex
                                      align-items-center
                                      profile__box
                                    "
                                  >
                                    <div class="content">
                                      <div class="name">
                                        {{ trimUrl(domain.url) }}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </template>
                              <li class="no_text_li">
                                <hr>
                                <template>
                                    <router-link class="add_new_custom_domain" :to="{ name: 'custom_domains' }"
                                    >Connect new custom domain.
                                    </router-link>
                                </template>
                              </li>
                            </ul>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 mb-4 profile__box avatar-block">
                  <div class="avatar">
                    <div
                      class="brand_img"
                      v-if="getBioLinkAdd.image"
                      :style="getBioBackgroundStyleImage(getBioLinkAdd.image)"
                    ></div>
                    <div
                      class="brand_img"
                      v-else
                      style="
                        background: url('https://storage.googleapis.com/replug/profile-default.png');
                      "
                    ></div>
                    <div v-if="bio_image_loader">
                      <clip-loader :color="color" :size="size"></clip-loader>
                    </div>
                  </div>
                  <div class="content">
                    <input
                      :disabled="bio_image_loader"
                      @change="storeBioImage($event)"
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      id="bio-avatar"
                      class="d-none"
                      style="display: none"
                    />
                    <label
                      for="bio-avatar"
                      class="
                        btn-small
                        cursor_pointer
                        btn---cta btn-blue btn-round
                      "
                    >
                      <span>Upload Avatar</span>
                    </label>
                    <!--<button class="mb-0 btn-&#45;&#45;cta light-blue btn-round">
                        <span>Remove</span>
                    </button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--//Appearance-->
          <div class="border-block-section">
            <div class="row block-heading mb-0">
              <div class="col-md-10">
                <h2>Appearance</h2>
                <p>
                  Customize your link appearance from the selected templates.
                </p>
              </div>
              <div class="col-md-2">
                <button v-b-toggle.collapse-appearance
                        v-if="!collapse_appearance"
                        class="btn---cta light-blue btn-round when-closed mt-20"
                        @click="collapse_appearance = true"
                >
                  <span>Edit</span>
                </button>
                <button v-b-toggle.collapse-appearance
                        v-if="collapse_appearance"
                        class="btn---cta btn-blue btn-round mt-20"
                        @click="collapse_appearance = false"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
            <b-collapse id="collapse-appearance" class="mt-2">
              <div class="block-area">
                <div class="block-list">
                  <template v-for="theme in themes">
                    <div
                      class="block-item-theme"
                      @click="bioThemeColorChange(theme)"
                      :class="[
                        { active: getBioLinkAdd.theme === theme },
                        theme,
                        {
                          'block-item-theme-gradient':
                            theme.includes('gradient'),
                        },
                      ]"
                    >
                      <span
                        class="
                          d-flex
                          align-items-center
                          justify-content-center
                          block-item-theme-span
                        "
                      >
                      </span>
                    </div>
                  </template>
                  <div
                    class="block-item-theme"
                    @click="bioThemeColorChange('customized')"
                    :class="[{ active: getBioLinkAdd.theme === 'customized' }]"
                  >
                    <span
                      class="
                        d-flex
                        align-items-center
                        justify-content-center
                        block-item-theme-span
                      "
                    >
                      <i class="fal fa-cog" style="font-size: 38px"></i>
                    </span>
                  </div>

                  <!-- customized theme section -->

                  <div
                    class="mt-3 mb-3 input_icon_text d-flex align-items-center"
                  >
                    <div
                      class="content"
                      v-if="getBioLinkAdd.theme === 'customized'"
                    >
                      <div class="url">Background Color</div>
                      <div class="name">
                        <input
                          v-model="getBioLinkAdd.custom_theme.background_color"
                          type="color"
                          name=""
                          class=""
                        />
                      </div>
                    </div>
                    <div class="content">
                      <div class="url">Block Title Color</div>
                      <div class="name">
                        <input
                          v-model="getBioLinkAdd.block_title_color"
                          type="color"
                          name=""
                          class=""
                        />
                      </div>
                    </div>
                  </div>
                  <template v-if="getBioLinkAdd.theme === 'customized'">
                    <div
                      class="
                        mt-3
                        mb-3
                        input_icon_text
                        d-flex
                        align-items-center
                      "
                    >
                      <div class="content">
                        <div class="url">Button Color</div>
                        <div class="name">
                          <input
                            v-model="getBioLinkAdd.custom_theme.button_color"
                            type="color"
                            name=""
                            class=""
                          />
                        </div>
                      </div>
                      <div class="content">
                        <div class="url">Button Text Color</div>
                        <div class="name">
                          <input
                            v-model="
                              getBioLinkAdd.custom_theme.button_text_color
                            "
                            type="color"
                            name=""
                            class=""
                          />
                        </div>
                      </div>
                    </div>

                    <!--                                        <div class=" mb-3 input_icon_text d-flex align-items-center ">-->
                    <!--                                            <div class="content">-->
                    <!--                                                <div class="url">-->
                    <!--                                                    Button Style-->
                    <!--                                                </div>-->

                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                        <div class="buttons_style">-->
                    <!--                                            <div class="button_style default"></div>-->
                    <!--                                        </div>-->
                  </template>

                  <div
                    class="mt-3 mb-3 input_icon_text d-flex align-items-center"
                  >
                    <div class="content">
                      <div class="url">Page Title Color</div>
                      <div class="name">
                        <input
                          v-model="getBioLinkAdd.title_color"
                          type="color"
                          name=""
                          class=""
                        />
                      </div>
                    </div>
                    <div class="content">
                      <div class="url">Page Sub-Title Color</div>
                      <div class="name">
                        <input
                          v-model="getBioLinkAdd.sub_title_color"
                          type="color"
                          name=""
                          class=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <!--//Seo-->
          <div class="border-block-section">
            <div class="row block-heading mb-0">
              <div class="col-md-10">
                <h2>SEO</h2>
                <p>
                  Add your custom meta title and description to improve the link
                  presence in search engine and social media channels.
                </p>
              </div>
              <div class="col-md-2">
                <button
                  v-b-toggle.collapse-seo
                  v-if="!collapse_seo"
                  class="btn---cta light-blue btn-round when-closed mt-20"
                  @click="collapse_seo = true"
                >
                  <span>Edit</span>
                </button>
                <button
                  v-b-toggle.collapse-seo
                  v-if="collapse_seo"
                  class="btn---cta btn-blue btn-round mt-20"
                  @click="collapse_seo = false"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
            <b-collapse id="collapse-seo" class="mt-2">
              <div class="block-area">
                <div class="block-area">
                  <div
                    :class="{'input-field-error': validations.mete_title_length}"
                    class="
                      mt-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                  >
                    <div class="content">
                      <div class="url">Title</div>
                      <div class="name">
                        <div class="validation_input">
                          <input
                            class=""
                            type="text"
                            placeholder="Add a title..."
                            v-model="getBioLinkAdd.meta.title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-error" v-if="validations.meta_title_length">The title should not be greater than 100 characters.</span>
                  <div
                    :class="{'input-field-error': validations.mete_description_length}"
                    class="
                      mt-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                  >
                    <div class="content">
                      <div class="url">Description</div>
                      <div class="name">
                        <div class="validation_input">
                          <input
                            class=""
                            type="text"
                            placeholder="Add a description..."
                            v-model="getBioLinkAdd.meta.description"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-error" v-if="validations.meta_description_length">The description should not be greater than 512 characters.</span>
                </div>
              </div>
            </b-collapse>
          </div>

          <!--                    OG Image-->
          <div class="border-block-section">
            <div class="row block-heading mb-0">
              <div class="col-md-10">
                <h2>Social Media (Open Graph Image)</h2>
                <p>
                  Social media services use Open Graph image tag to define what
                  image is displayed when a post is shared.
                </p>
              </div>
              <div class="col-md-2">
                <button
                  v-b-toggle.collapse-graph
                  v-if="!collapse_graph_image"
                  class="btn---cta light-blue btn-round when-closed mt-20"
                  @click="collapse_graph_image = true"
                >
                  <span>Edit</span>
                </button>
                <button
                  v-b-toggle.collapse-graph
                  v-if="collapse_graph_image"
                  class="btn---cta btn-blue btn-round mt-20"
                  @click="collapse_graph_image = false"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
            <b-collapse id="collapse-graph" class="mt-2">
              <div class="block-area">
                <div class="block-area graph_image">
                  <!--                                    <vue2-dropzone class="mt-3 mb-3  input_icon_text d-flex align-items-center profile__box" :options="dropzoneOptions">-->
                  <!--                                    </vue2-dropzone>-->

                  <div
                    class="
                      mt-3
                      mb-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                    "
                  >
                    <div class="content" v-if="!getBioLinkAdd.meta.image">
                      <!--                                            <div class="url">Image</div>-->
                      <div class="name">
                        <div class="validation_input text-center">
                          <input
                            class="d-none"
                            @change="storeGraphImage($event)"
                            id="graph_image"
                            type="file"
                            accept=".png, .jpg, .jpeg, .gif"
                          />
                          <label
                            :disabled="graph_image_loader"
                            for="graph_image"
                            class="
                              btn-small
                              cursor_pointer
                              btn---cta btn-blue btn-round
                            "
                          >
                            <span>Upload Image</span>
                          </label>
                          <!--                                                    <p>or drag an image here</p>-->
                          <clip-loader
                            v-if="graph_image_loader"
                            :color="color"
                            :size="size"
                          ></clip-loader>
                        </div>
                      </div>
                    </div>
                    <div v-if="getBioLinkAdd.meta.image" class="text-center">
                      <i
                        class="fa fa-times delete_graph_image"
                        @click="removeGraphImage()"
                      ></i>
                      <div
                        class="brand_img"
                        style="width: 280px; height: 230px; margin-left: 170px"
                        :style="
                          getBioBackgroundStyleImage(getBioLinkAdd.meta.image)
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <!--                    Favicon Image-->
          <div class="border-block-section">
            <div class="row block-heading mb-0">
              <div class="col-md-10">
                <h2>Favicon</h2>
                <p>Add your custom favicon image.</p>
              </div>
              <div class="col-md-2">
                <button
                  v-b-toggle.collapse-favicon
                  v-if="!collapse_favicon_image"
                  class="btn---cta light-blue btn-round when-closed mt-20"
                  @click="collapse_favicon_image = true"
                >
                  <span>Edit</span>
                </button>
                <button
                  v-b-toggle.collapse-favicon
                  v-if="collapse_favicon_image"
                  class="btn---cta btn-blue btn-round mt-20"
                  @click="collapse_favicon_image = false"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
            <b-collapse id="collapse-favicon" class="mt-2">
              <div class="block-area">
                <div class="block-area graph_image">
                  <div
                    class="
                      mt-3
                      mb-3
                      input_icon_text
                      d-flex
                      align-items-center
                      profile__box
                      favicon_box
                    "
                  >
                    <div class="content" v-if="!getBioLinkAdd.favicon">
                      <div class="name">
                        <div class="validation_input text-center">
                          <input
                            class="d-none"
                            @change="storeFaviconImage($event)"
                            id="favicon_image"
                            type="file"
                            accept=".png, .jpg, .jpeg, .gif"
                          />
                          <label
                            :disabled="favicon_image_loader"
                            for="favicon_image"
                            class="
                              btn-small
                              cursor_pointer
                              btn---cta btn-blue btn-round
                            "
                          >
                            <span>Upload Image</span>
                          </label>
                          <!--                                                    <p>or drag an image here</p>-->
                          <clip-loader
                            v-if="favicon_image_loader"
                            :color="color"
                            :size="size"
                          ></clip-loader>
                        </div>
                      </div>
                    </div>
                    <div v-if="getBioLinkAdd.favicon" class="text-center">
                      <i
                        class="fa fa-times delete_graph_image"
                        @click="removeFaviconImage()"
                      ></i>
                      <div
                        class="brand_img"
                        style="width: 80px; height: 80px; margin-left: 260px"
                        :style="
                          getBioBackgroundStyleImage(getBioLinkAdd.favicon)
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <!--//Retargeting Pixels-->
          <div class="border-block-section">
            <div class="row block-heading">
              <div class="col-md-10">
                <h2>Retargeting Pixels</h2>
                <p>
                  Retargeting pixels makes conversion tracking, optimization and
                  remarketing easier than ever. Once placed on your page, you
                  will be able to measure cross device conversions, create
                  lookalike audiences, optimize delivery to people that likely
                  to take action and much more.
                </p>
              </div>
              <div class="col-md-2">
                <button
                  v-b-toggle.collapse-pixel
                  v-if="!collapse_pixel"
                  class="btn---cta light-blue btn-round when-closed mt-20"
                  @click="collapse_pixel = true"
                >
                  <span>Edit</span>
                </button>
                <button
                  v-b-toggle.collapse-pixel
                  v-if="collapse_pixel"
                  class="btn---cta btn-blue btn-round mt-20"
                  @click="collapse_pixel = false"
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
            <b-collapse id="collapse-pixel" class="mt-2">
              <div class="block-area">
                <div class="block-area">
                  <retargeting :callType="'bio_links'" :addPixel="getBioLinkAdd"
                               :pixelListKey="'retargeting_pixels'"></retargeting>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {bioLinkTypes} from '@/state/modules/mutation-types'
import {mapActions, mapGetters} from 'vuex'
import BioShortenedURL from './BioShortenedURL'
import {DARK_PURPLE_LOADER_COLOR, DEFAULT_IMAGE} from '@/common/constants'
import bioLinkMixin from '@/mixins/bio-link-mixin'
import vue2Dropzone from 'vue2-dropzone'
import Retargeting from "@/views/pages/campaigns/call-to-actions/steps/retargeting/Retargeting";

export default {
  mixins: [bioLinkMixin],
  data () {
    return {
      themes: [
        'white_and_orange',
        'white_and_blue',
        'white_and_purple',
        'white_and_pink',
        'white_and_green',
        'black_and_gradient',
        'green_and_gradient',
        'blue_and_gradient',
        'pink_and_gradient',
      ],
      bio_image_loader: false,
      graph_image_loader: false,
      favicon_image_loader: false,
      color: DARK_PURPLE_LOADER_COLOR,
      size: '12px',
      validations: {
        brand_required: false,
        title_required: false,
        title_length: false,
        page_sub_title_length: false,
        meta_title_length: false,
        meta_description_length: false
      },
      collapse_seo: false,
      collapse_appearance: false,
      collapse_graph_image: false,
      collapse_favicon_image: false,
      collapse_pixel: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: '<i class=\'fa fa-cloud-upload\'></i>UPLOAD ME',
      },
      default_domain: null,
      bioLinkSaveLoader: false
    }
  },
  components: {
    'bio-shortened-url': BioShortenedURL,
    Retargeting,
    vue2Dropzone,
  },
  computed: {
    ...mapGetters(['getBioLinkAdd', 'getComponentBrandList', 'getDefaultDomainEnv', 'getBrandLoaders', 'getBioLinkItems', 'getBrand', 'getBrandList'])
  },
  async created () {
    this.default_domain = this.getDefaultDomainEnv.biolink
    if (!this.getBioLinkAdd.first_step_verify)
      this.$router.push({name: 'select_content'})
  },
  methods: {
    ...mapActions(['setBioLinkDomain']),
    navigateContentLinksBlock () {
      this.$store.commit(bioLinkTypes.SET_BIO_CONTENT_SECTION_TYPE, 'links')
      this.$router.push({name: 'content_links_block'})
    },
    bioThemeColorChange (colorCode) {
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_THEME, colorCode)
    },
    async validateAndStoreWithBio () {
      if (!this.getBioLinkAdd.brand_id) this.validations.brand_required = true
      this.validations.title_required = this.requiredCheck(this.getBioLinkAdd.page_title)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)
      if (result) {
        this.validations.title_required = false
        this.validations.brand_required = false
        this.getBioLinkAdd.name = this.getBioLinkAdd.page_title
        this.bioLinkSaveLoader = true
        const res = await this.$store.dispatch('storeBio', 'active')
        this.bioLinkSaveLoader = false
        if (!res.data.status) {
          this.alertMessage(res.data.message, 'error')
        } else {
          this.$router.push({name: 'bio_links'})
        }
      }
    },
    selectBioDomain (domain) {
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN, domain.shorten_domain)
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_SHORTEN_DOMAIN_ID, domain.domain_id)

      const domainObj = this.getDomains.items.find((obj => {
        return obj._id === domain.domain_id
      }))
      if (!domainObj) {
        this.setBioLinkDomain(this.getDefaultDomainEnv.biolink)
        this.$refs.bio_domains_dropdown_menu.visible = false
        return
      }
      if (domainObj && domainObj.url.substr(-1) !== '/') {
        domainObj.url = domainObj.url + '/'
      }
      this.setBioLinkDomain(domainObj)
      this.$refs.bio_domains_dropdown_menu.visible = false
    },
    async storeBioImage ($event) {
      this.bio_image_loader = true
      let res = await this.$store.dispatch('uploadBioImage', $event)
      this.bio_image_loader = false
      if (res) {
        if (res.data.status) {
          this.$store.commit(bioLinkTypes.SET_BIO_ADD_IMAGE, res.data.url)
          this.$store.commit(
            bioLinkTypes.SET_BIO_ADD_RESIZED_IMAGE,
            res.data.resize_url
          )
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },
    async storeGraphImage ($event) {
      this.graph_image_loader = true
      let res = await this.$store.dispatch('uploadGraphImage', $event)
      this.graph_image_loader = false
      if (res) {
        if (res.data.status) {
          this.$store.commit(bioLinkTypes.SET_GRAPH_ADD_IMAGE, res.data.url)
          this.$store.commit(
            bioLinkTypes.SET_GRAPH_ADD_RESIZED_IMAGE,
            res.data.resize_url
          )
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },
    async storeFaviconImage ($event) {
      this.favicon_image_loader = true
      let res = await this.$store.dispatch('uploadGraphImage', $event)
      this.favicon_image_loader = false
      if (res) {
        if (res.data.status) {
          this.$store.commit(bioLinkTypes.SET_FAVICON_ADD_IMAGE, res.data.url)
        } else {
          this.$store.dispatch('toastNotification', {
            message: res.data.message,
            type: 'error',
          })
        }
      }
    },
    selectBrandForBio (brand, image) {
      this.validations.brand_required = false
      const brandImage = image || DEFAULT_IMAGE
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_BRAND, brand)
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_IMAGE, brandImage)
      this.$refs.bio_brand_dropdown_menu.visible = false
      // this.$router.push({ name: 'select_content' })
      this.getSelectedBioBrand()
    },
    removeGraphImage () {
      this.$store.commit(bioLinkTypes.SET_GRAPH_ADD_IMAGE, null)
      this.$store.commit(bioLinkTypes.SET_GRAPH_ADD_RESIZED_IMAGE, null)
      this.getBioLinkAdd.meta.image = null
      this.getBioLinkAdd.meta.resized_image = null
    },
    removeFaviconImage () {
      this.$store.commit(bioLinkTypes.SET_FAVICON_ADD_IMAGE, null)
      this.getBioLinkAdd.favicon = null
    },
    onDragLeaveFeeder (event) {},
    onDragOverFeeder (event) {},
    onDropFeeder (event) {
      event.stopPropagation()
      event.preventDefault()
    }
  },
  watch: {
    'getBioLinkAdd.page_title' (value) {
      if (value && value.length > 0) {
        this.validations.title_required = false
        this.validations.title_length = !this.maxLength(value, 100)
      }
    },
    'getBioLinkAdd.page_sub_title' (value) {
      if (value && value.length > 0) {
        this.validations.page_sub_title_length = !this.maxLength(value, 100)
      }
    },
    'getBioLinkAdd.meta.title' (value) {
      if (value && value.length > 0) {
        this.validations.meta_title_length = !this.maxLength(value, 100)
      }
    },
    'getBioLinkAdd.meta.description' (value) {
      if (value && value.length > 0) {
        this.validations.meta_description_length = !this.maxLength(value, 512)
      }
    }
  }
}
</script>

<style lang="less">
.add_new_custom_domain {
  color: #1d8bfa;
  &:hover {
    text-decoration: underline;
    color: #1d8bfa;
  }
}
.opml-file-picker {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  color: #9e9e9e;
  cursor: pointer;
  display: flex;
  font-size: 1.25rem;
  height: 250px;
  justify-content: center;
  outline: 3px dashed rgba(0, 0, 0, 0.05);
  outline-offset: 5px;
  position: relative;
  width: 100%;

  .hint {
    display: block;
    font-size: 0.875rem;
    margin-top: 1rem;
    text-align: center;
  }

  input {
    display: none;
  }
}

.bio_link_layout
.bio_link_main_view
.view_inner
.view_center
.steps_dot
.circles {
  width: 495px;
  text-align: center;
  display: inline-block;
}

.block_content {
  min-width: 430px;
  max-width: 430px;
  display: inline-block;
}

.bio_link_layout .block-area .avatar-block {
  margin-top: 120px;
  display: inline-block;
  min-width: 225px;
}

.bio_link_layout .block-area .block-list .block-item-theme {
  margin-left: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.graph_image {
  .input_icon_text {
    background-color: #e2e6e6;
    border: 1px dotted #ccc;
    height: 330px;
  }
}

.delete_graph_image {
  display: block;
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
}

.favicon_box {
  height: 140px !important;
}
</style>
